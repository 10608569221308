import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { SlideYUpTransition } from "vue2-transitions";
import { debounce } from "throttle-debounce"; //@V

const MIN_SEARCH_LEN = 2; //&:

export default {
  components: {
    SlideYUpTransition
  },
  props: {
    limit: {
      type: Number,
      default: 10
    },
    placeholder: {
      type: String,
      default: ""
    },
    loader: {
      type: Function
    },
    getRoute: {
      type: Function,
      default: null
    },
    textProp: {
      type: String,
      required: true
    },
    cleanOnSelect: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ""
    },
    selectedMp: {
      type: String,
      default: ""
    },
    packetInput: {
      type: Boolean,
      default: false
    },
    limitLinesNumber: {
      type: Number,
      default: 20
    },
    keywordAnayzeType_id: {
      type: String,
      default: ""
    },
    execute: {
      type: Number,
      default: 0
    },
    noEmpty: {
      type: Boolean,
      default: false
    },
    directInput: {
      type: Boolean,
      default: false
    },
    limitReached: {
      type: Boolean
    }
  },
  data() {
    return {
      search_input: this.value,
      search_input_focused: false,
      show_search_results: false,
      search_results: [],
      search_input_manual_selected: -1,
      loading: false,
      searchDebouncedFunction: null,
      //@V
      cols: 10000
    };
  },
  computed: {
    results() {
      let rc = this.search_results.map(item => {
        // const result = {
        //     item: item,
        //     text: this.formatSearchResult(item),
        // };
        // if (!this.directInput) {
        //     result.route = this.getRoute && this.getRoute(item);
        // }
        return {
          item: item,
          route: this.getRoute && this.getRoute(item),
          text: this.formatSearchResult(item)
        };
      });

      //console.log("210: results=", rc);

      return rc;
    }
  },
  methods: {
    focus_search_input() {
      this.search_input_focused = true;
      //// @V: if (this.search_input.length > 0) this.show_search_results=true
      if (this.search_input.length >= MIN_SEARCH_LEN) this.show_search_results = true; //!&:
    },
    onBlur() {
      ////console.log('onBlur.234')
      this.unfocus_input();
    },
    unfocus_input(forceBlur = false) {
      if (forceBlur) {
        this.$refs.input.blur();
      }
      this.search_input_focused = false;
      this.show_search_results = false;
      this.search_input_manual_selected = -1;
    },
    change_search_input() {
      this.search_input_manual_selected = -1;

      //console.log(".248: this.keywordAnayzeType_id=",this.keywordAnayzeType_id);

      if (this.keywordAnayzeType_id == "url") {
        this.show_search_results = false;
        this.search_results = [];
        return;
      }

      ////@V: if (this.search_input.length == 0) {
      if (this.search_input.length < MIN_SEARCH_LEN) {
        //!&:
        this.show_search_results = false;
        this.search_results = [];
      }
      ////@V: if (this.search_input.length > 0 & !this.show_search_results) {
      if (this.search_input.length >= MIN_SEARCH_LEN && !this.show_search_results) {
        this.show_search_results = true;
      }
      ////@V:  if (this.search_input.length > 0) {
      if (this.search_input.length >= MIN_SEARCH_LEN) {
        //!&:
        ////&:SRC: this.search(this.search_input)
        this.searchDebounced(this.search_input); //@V
      }
    },
    searchDebounced(query) {
      this.loading = true;
      if (!this.searchDebouncedFunction) {
        //// @V: this.searchDebouncedFunction = debounce(300, this.search) //@V
        this.searchDebouncedFunction = debounce(500, this.search); //!&:
      }
      this.searchDebouncedFunction(query);
    },
    async search(query) {
      ////&: ждем пока завершится загрузка
      //// НЕ ТАК: if( this.loading == true ) return;

      this.loading = true;
      let data = this.loader ? await this.loader(query) : [];

      ////console.log('262: data=', data)

      this.loading = false;
      this.search_results = data.slice(0, this.limit + 1);
      if (this.search_results.length) {
        this.search_input_manual_selected = 0;
      } else {
        this.search_input_manual_selected = -1;
      }
    },
    formatSearchResult(current) {
      /*
      //@V: подцветка 1го слова
      // let reggie = new RegExp(this.search_input, "ig");
      // let pos = current[this.textProp].search(reggie)
      // if (pos == -1) {
      //     return current[this.textProp]
      // } else {
      //     const len_search = this.search_input.length
      //     let res = `${current[this.textProp].substring(0, pos)}<span class="blue-text">${current[this.textProp].substring(pos, pos+len_search)}</span>${current[this.textProp].substring(pos+len_search)}`
      //     return res
      // }
      */

      //!&: SF: 2022-01-09 подцветка всех слов из query
      var query = this.search_input;
      var v = current[this.textProp];
      //! далее аналогично /salesfinder/src/components/GlobalSearch/GlobalSearch.vue :: const highlight = v => { ...
      //let aLIKE = query.trim().split(/[.,<> -]/).filter(w => w.length>=3);
      let aLIKE = query.trim().split(/[, ]/).filter(w => w.length >= MIN_SEARCH_LEN);
      //E(_T(_F), 'aLIKE=', aLIKE)

      var res = v;
      for (let iw = 0; iw < aLIKE.length; iw++) {
        let w = aLIKE[iw].trim();
        if (w == "") continue;
        let re = new RegExp(w, "ig");
        let pos = res.search(re);
        if (pos == -1) {
          continue;
        } else {
          //не попали на разметку?
          let qTag = 0;
          //смотрим влево
          for (let i = pos; i >= 0; i--) {
            if (res.substring(i, i + 1) == ">") {
              qTag = 0;
              break;
            } else if (res.substring(i, i + 1) == "<") {
              qTag = 1;
              break;
            }
          }
          if (qTag == 1) continue;
          //смотрим вправо
          for (let i = pos; i < res.length; i++) {
            if (res.substring(i, i + 1) == "<") {
              qTag = 0;
              break;
            } else if (res.substring(i, i + 1) == ">") {
              qTag = 1;
              break;
            }
          }
          if (qTag == 1) continue;
          let lW = w.length;
          res = `${res.substring(0, pos)}<span class="blue-text">${res.substring(pos, pos + lW)}</span>${res.substring(pos + lW)}`;
        }
      }
      return res;
    },
    select_prev_search_item() {
      if (this.search_results.length > 0) {
        this.search_input_manual_selected -= 1;
        if (this.search_input_manual_selected < 0) this.search_input_manual_selected = this.search_results.length - 1;
      }
    },
    select_next_search_item() {
      if (this.search_results.length > 0) {
        this.search_input_manual_selected += 1;
        if (this.search_input_manual_selected == this.search_results.length) this.search_input_manual_selected = 0;
      }
    },
    searchForKwd() {
      if (this.limitReached) {
        this.$modal.open(() => import("@/components/modals/access/ModalLimitReachedKwd"));
      } else {
        let item = this.results.find(item => item.item.keyword === this.search_input);
        switch (this.keywordAnayzeType_id) {
          case "std":
            this.select_search_item(item ? item : this.results[0]);
            break;
          case "live":
            this.select_search_item(null);
            break;
          case "url":
            this.select_search_item(null);
            break;
          default:
            break;
        }
      }
    },
    async select_search_item(item) {
      //console.log("select_search_item.382: item=", item);
      //console.log("select_search_item.382: this.keywordAnayzeType_id=",this.keywordAnayzeType_id);

      if (!item) {
        if (["std", "live", "url"].includes(this.keywordAnayzeType_id)) {
          //console.log("EntitySearch.398: item=", item);
          //console.log("EntitySearch.398: this.search_input=",this.search_input);

          //kwd
          let kwd = this.search_input;
          let mp = null,
            keyword = null;
          if (this.keywordAnayzeType_id == "std") {
            mp = this.$route.params.mp;
            keyword = this.search_input;
          } else if (this.keywordAnayzeType_id == "live") {
            mp = this.$route.params.mp;
            keyword = this.search_input;
          } else if (this.keywordAnayzeType_id == "url") {
            this.qTypeUrlBadFormat = 0;
            let bzUrl = null;
            try {
              bzUrl = new URL(this.search_input);
              //console.log("EntitySearch.405: bzUrl=", bzUrl);

              mp = bzUrl.host.indexOf("www.ozon.ru") >= 0 ? "ozon" : "wb";
              keyword = bzUrl.searchParams.get(mp == "wb" ? "search" : "text");
              this.$emit("qTypeUrlBadFormat", this.qTypeUrlBadFormat);
            } catch (e) {
              //console.log("EntitySearch.478: qTypeUrlBadFormat=1");
              this.qTypeUrlBadFormat = 1;
              this.$emit("qTypeUrlBadFormat", this.qTypeUrlBadFormat);

              /*
              this.$toast.error('Плохой URL', {
                  position: "bottom-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
              });
              */

              return;
            }
          }

          //console.log("EntitySearch.415: mp=", mp);
          //console.log("EntitySearch.412: keyword=", keyword);

          keyword = decodeURI(keyword);
          keyword = keyword.replaceAll("+", " ");
          //console.log("EntitySearch.416: keyword=", keyword);

          const reportSettings = {
            //std
            mp: mp,
            //k_id: this.$route.params.k_id,
            kwd: keyword
            //date: this.calendar[0],
            //date2: this.calendar[1],
            //fbs: this.fbs,
            //...this.$route.query
          };
          let oKwd = await this.$store.dispatch("keyword/getKeyword", {
            ...reportSettings
          });

          //имитируем keyword выбранный из select
          let item2 = {
            k_id: oKwd.k_id,
            //k_id: 0,
            mp: mp,
            item: {
              k_id: oKwd.k_id,
              mp: mp,
              kwd: kwd
            }
          };

          //console.log("select_search_item.467: item2=", item2);

          let route2 = this.getRoute(item2);

          //console.log("select_search_item.471: route2=", route2);

          this.$router.push(route2);
          this.unfocus_input(true);
          return;
        }
        console.log("0", this.results);
        if (this.search_input_manual_selected !== -1) {
          console.log("1", this.search_input_manual_selected);
          item = this.results[this.search_input_manual_selected];
        } else if (this.results && this.results.length) {
          console.log("2", this.results);
          item = this.results[0];
        } else {
          return;
        }
      }
      if (!item.route) {
        //console.log("select_search_item.389: item=", item);

        this.$emit("select", item.item);
        if (this.cleanOnSelect) {
          this.search_input = "";
        }
        this.unfocus_input(true);
        return;
      }

      //console.log("select_search_item.389: ->push(", item.route, ")");

      this.$router.push(item.route);
      this.unfocus_input(true);
      // this.unfocus_search_input()
    },
    onSelect(item) {
      //console.log("EntitySearch.398: item=", item);

      this.$emit("select", item.item);
      this.unfocus_input(true);
      if (this.directInput) {
        this.search_input = item.item.keyword;
      }
      if (this.cleanOnSelect) {
        this.search_input = "";
      }
    },
    limitLines() {
      let lines = this.search_input.split("\n");
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].length <= this.cols) continue;
        let j = 0;
        let space = this.cols;
        while (j++ <= this.cols) {
          if (lines[i].charAt(j) === " ") space = j;
        }
        lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
        lines[i] = lines[i].substring(0, space);
      }
      if (this.limitLinesNumber) {
        this.search_input = lines.slice(0, this.limitLinesNumber).join("\n");
      } else {
        this.search_input = lines.join("\n");
      }
    }
  },
  watch: {
    value(value) {
      this.search_input = value;
      this.change_search_input();
      this.$refs.input.focus();
    },
    selectedMp() {
      this.change_search_input();
    },
    search_input(value) {
      this.$emit("change-value", value);
    },
    execute() {
      // eslint-disable-line
      // console.log(
      //     "execute.627: val=", val,
      //     "oldVal=", oldVal);

      this.searchForKwd();
    },
    //&:
    keywordAnayzeType_id: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.show_search_results = !!newVal || newVal === "url" ? false : true;
          this.qTypeUrlBadFormat = 0;
          this.$emit("qTypeUrlBadFormat", this.qTypeUrlBadFormat);
        }
      }
    }
  },
  emits: ["change-value"]
};