var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.packetInput ? _c('div', {
    staticClass: "main-search main-search-packet",
    class: {
      'main-search-active': _vm.search_input_focused,
      simple: _vm.simple
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search_input,
      expression: "search_input"
    }],
    ref: "textarea",
    staticClass: "main-search-input main-search-textarea",
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.search_input
    },
    on: {
      "focus": _vm.focus_search_input,
      "blur": _vm.onBlur,
      "keyup": _vm.limitLines,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search_input = $event.target.value;
      }
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "flex-start",
      "height": "100%"
    }
  }, [_c('button', {
    staticClass: "main-search-btn"
  }, [_c('svg', {
    attrs: {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.66668 11C6.84999 10.9998 7.99921 10.6036 8.93134 9.87468L11.862 12.8053L12.8047 11.8627L9.87401 8.93201C10.6033 7.99979 10.9997 6.8503 11 5.66668C11 2.72601 8.60734 0.333344 5.66668 0.333344C2.72601 0.333344 0.333344 2.72601 0.333344 5.66668C0.333344 8.60734 2.72601 11 5.66668 11ZM5.66668 1.66668C7.87268 1.66668 9.66668 3.46068 9.66668 5.66668C9.66668 7.87268 7.87268 9.66668 5.66668 9.66668C3.46068 9.66668 1.66668 7.87268 1.66668 5.66668C1.66668 3.46068 3.46068 1.66668 5.66668 1.66668Z",
      "fill": "black",
      "fill-opacity": "0.45"
    }
  })])])])]) : _c('div', {
    staticClass: "main-search",
    class: {
      'main-search-active': _vm.search_input_focused,
      simple: _vm.simple
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search_input,
      expression: "search_input"
    }],
    ref: "input",
    staticClass: "main-search-input",
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.search_input
    },
    on: {
      "focus": _vm.focus_search_input,
      "blur": _vm.onBlur,
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.search_input = $event.target.value;
      }, _vm.change_search_input],
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) return null;
        return _vm.select_prev_search_item.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) return null;
        return _vm.select_next_search_item.apply(null, arguments);
      }],
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.directInput ? _vm.searchForKwd() : _vm.select_search_item();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) return null;
        return _vm.unfocus_input(true);
      }]
    }
  }), _vm.simple ? [_c('svg', {
    attrs: {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.27001 14.5392C8.94756 14.5392 10.4897 13.9622 11.7202 13.0053L15.715 17L17 15.7151L13.0051 11.7204C13.963 10.4891 14.54 8.94708 14.54 7.26962C14.54 3.26133 11.2785 0 7.27001 0C3.26151 0 0 3.26133 0 7.26962C0 11.2779 3.26151 14.5392 7.27001 14.5392ZM7.27001 1.8174C10.2771 1.8174 12.7225 4.26272 12.7225 7.26962C12.7225 10.2765 10.2771 12.7218 7.27001 12.7218C4.26295 12.7218 1.8175 10.2765 1.8175 7.26962C1.8175 4.26272 4.26295 1.8174 7.27001 1.8174Z",
      "fill": "black",
      "fill-opacity": "0.9"
    }
  })])] : _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('button', {
    staticClass: "main-search-btn"
  }, [_c('svg', {
    attrs: {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.66668 11C6.84999 10.9998 7.99921 10.6036 8.93134 9.87468L11.862 12.8053L12.8047 11.8627L9.87401 8.93201C10.6033 7.99979 10.9997 6.8503 11 5.66668C11 2.72601 8.60734 0.333344 5.66668 0.333344C2.72601 0.333344 0.333344 2.72601 0.333344 5.66668C0.333344 8.60734 2.72601 11 5.66668 11ZM5.66668 1.66668C7.87268 1.66668 9.66668 3.46068 9.66668 5.66668C9.66668 7.87268 7.87268 9.66668 5.66668 9.66668C3.46068 9.66668 1.66668 7.87268 1.66668 5.66668C1.66668 3.46068 3.46068 1.66668 5.66668 1.66668Z",
      "fill": "black",
      "fill-opacity": "0.45"
    }
  })])])]), _c('slide-y-up-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show_search_results && (_vm.search_results.length > 0 || !_vm.loading && _vm.noDataText && !_vm.noEmpty),
      expression: "\n                show_search_results &&\n                (search_results.length > 0 ||\n                    (!loading && noDataText && !noEmpty))\n            "
    }],
    staticClass: "main-search-result"
  }, [_vm.search_results.length > 0 ? _vm._l(_vm.results, function (item, i) {
    return _c(item.route && !_vm.directInput ? 'router-link' : 'div', {
      key: i,
      tag: "component",
      staticClass: "main-search-resilt-item",
      class: {
        'main-search-resilt-item-selected': i == _vm.search_input_manual_selected
      },
      attrs: {
        "to": item.route
      },
      on: {
        "click": function ($event) {
          item.route && !_vm.directInput ? null : _vm.onSelect(item);
        },
        "mousedown": function ($event) {
          $event.preventDefault();
        }
      },
      nativeOn: {
        "mousedown": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_vm.$scopedSlots.item ? [_vm._t("item", null, {
      "item": item.item,
      "text": item.text
    })] : _c('span', {
      domProps: {
        "innerHTML": _vm._s(item.text)
      }
    })], 2);
  }) : [_c('div', {
    staticClass: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.noDataText) + " ")])]], 2)])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };